import { ApiService } from "src/app/services/api/api.service";
import { EnvironmentService } from "src/app/services/environment/environment.service";
import { GeolocationService } from "./../../services/geolocation/geolocation.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NotyfService } from "src/app/services/notyf/notyf.service";

import { merge, Observable, of, OperatorFunction, Subject, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, filter } from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  el: HTMLElement;
  input: HTMLInputElement;
  parent: HTMLElement;

  locationPermissionGiven: boolean = false;

  @Input() search_location: string = "";
  @Input() search_query: string = "";

  private permission_sub: Subscription = null;

  date_input: string = "";
  time_input: string = "";

  on_bonnie: boolean;

  get date_url() {
    return encodeURIComponent(this.date_input);
  }

  get time_url() {
    return encodeURIComponent(this.time_input);
  }

  // for typeahead
  searching = false;
  searchFailed = false;
  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    console.log("typeahead function called");

    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        // do search
        this.api.get(`https://api.postcodes.io/postcodes/${term}/autocomplete`).pipe(
          map((response: { status: number | string; result: string[] }) => {
            console.log({ autocomplete: response });
            return response.result;
          }),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );
  };

  log = (message) => console.log(message);

  search2 = (text$: Observable<string>) => {
    console.log("typeahead2 function called");

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen())); //<---HERE
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        this.api.get(`https://api.postcodes.io/postcodes/${term}/autocomplete`).pipe(
          map((response: { status: number | string; result: string[] }) => {
            console.log({ autocomplete: response });
            return response.result;
          }),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      )
    );
  };

  constructor(
    private notyf: NotyfService,
    private geolocation: GeolocationService,
    private env: EnvironmentService,
    private api: ApiService
  ) {}

  async ngOnInit() {
    // watch for when an element is visble in the viewport (page)
    const observer = new IntersectionObserver(
      function (entries) {
        // isIntersecting is true when element and viewport are overlapping (visible on page)
        // isIntersecting is false when element and viewport don't overlap (element is off page)
        if (entries[0].isIntersecting === true) {
          // element visible
        } else {
          // element not visible
        }
      },
      { threshold: [0] }
    );

    observer.observe(document.querySelector("app-search"));

    this.locationPermissionGiven = this.geolocation.hasPermission();
    this.permission_sub = this.geolocation.permission$.subscribe((permissionGiven: boolean) => {
      this.locationPermissionGiven = permissionGiven;

      if (this.permission_sub !== null) {
        this.permission_sub.unsubscribe();
      }
    });

    this.on_bonnie = this.env.api.salon_id === null;
  }

  getLocation(el: HTMLElement) {
    this.el = el;
    this.parent = el.closest(".input-group");
    this.input = this.parent.querySelector(".form-control");

    this.geolocation
      .getAddress()
      .then((address) => {
        this.input.value = address;
      })
      .catch((error) => {
        this.notyf.error("There was an error getting your location.");
        console.log({ error });
      });
  }
}
