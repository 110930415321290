import { ModalController } from "@ionic/angular";
import { Component, Input } from "@angular/core";
import { Service } from "src/app/interfaces/service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-service-modal",
  templateUrl: "./service-modal.page.html",
  styleUrls: ["./service-modal.page.scss"],
})
export class ServiceModalPage {
  @Input() service: Service;

  constructor(private modalController: ModalController, public activeModal: NgbActiveModal) {}

  async ngOnInit() {

    console.log(this.service);

  }

}
