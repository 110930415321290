import { EnvironmentService } from './environment.service';
import { environment as appEnv } from '../../../environments/environment';

export const EnvironmentServiceFactory = () => {
    const environment = new EnvironmentService;
    const browserWindow = window || {};
    const browserWindowEnviroment = browserWindow['__env'] || {};

    let keys = Object.keys(environment);

    if (keys.length === 0) {
        keys = Object.keys(browserWindowEnviroment);
    }

    for (const index in keys) {
        const key = keys[index];

        if (browserWindowEnviroment.hasOwnProperty(key)) {
            environment[key] = browserWindowEnviroment[key];
        } else if (appEnv.hasOwnProperty(key)) {
            environment[key] = appEnv[key];
        }
    }

    return environment;
}

export const EnvironmentServiceProvider = {
    provide: EnvironmentService,
    useFactory: EnvironmentServiceFactory,
    deps: [],
}