import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      value = 0;
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return (value < 0 ? '-' : '') + '£' + (value.toFixed(2).replace('-', '')); 
  }
}
