import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appClickPreventDefault]",
})
export class ClickPreventDefaultDirective {
  @HostListener("click", ["$event"])
  public onClick(event: any) {
    console.log("clicked");
    event.preventDefault();
    event.stopPropagation();
  }
}
