import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Geolocation, GeolocationOptions, Geoposition } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  latitude: number = null;
  longitude: number = null;

  address: string = null;

  permission: boolean = false;

  permission$: Observable<boolean>;
  private permissionSubject: BehaviorSubject<boolean>;

  get latLng(): {latitude: number, longitude: number} {
    return {
      latitude: this.latitude,
      longitude: this.longitude
    };
  }

  constructor(
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    private platform: Platform,
    private http: HttpClient
  ) {
    this.permissionSubject = new BehaviorSubject<boolean>(this.permission);
    this.permission$ = this.permissionSubject.asObservable();
  }

  private options: GeolocationOptions = {
    enableHighAccuracy: true, 
    maximumAge: 3600,
    timeout: 5000
  };

  private nativeGeocoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };

  async getCurrentCoordinates() {
    try {
      const response: Geoposition = await this.geolocation.getCurrentPosition(this.options)
  
      this.latitude = response.coords.latitude;
      this.longitude = response.coords.longitude;

      this.updateAddress(this.latitude, this.longitude);

      this.permission = true;
      this.permissionSubject.next(true);

      return response.coords;
    }
    catch (error) {
      console.log('There was an error fetching your co-ordinates');
      console.log({error});

      if (error.message == 'User denied Geolocation') {
        this.permission = false;
      }

      return {
        latitude: null,
        longitude: null
      }
    }
  }

  hasPermission() {
    return this.permission;
  }

  async getAddress() {
    if (this.address === null && this.hasPermission()) {
      if (this.latitude === null && this.longitude === null) {
        await this.getCurrentCoordinates();
      }

      return await this.updateAddress(this.latitude, this.longitude);
    }

    return this.address;
  }

  // get address using coordinates
  async updateAddress(latitude: number, longitude: number) {
    if (this.platform.is('cordova')) {
      try {
        const response: NativeGeocoderResult[] = await this.nativeGeocoder.reverseGeocode(latitude, longitude, this.nativeGeocoderOptions);

        this.address = this.pretifyAddress(response[0]);
    
        return this.address;
      }
      catch (error) {
        alert('Error getting location: '+ JSON.stringify(error));
      }
    } else {
      const response: any = await this.http.get(`https://nominatim.openstreetmap.org/reverse?lat=${this.latitude}&lon=${this.longitude}&format=json`).toPromise();
      
      this.address = response.display_name;

      return this.address;
    }
  }

  // address
  pretifyAddress(address: NativeGeocoderResult) {
    let string = '';

    string += address.thoroughfare + ', ';
    string += address.subAdministrativeArea + ', ';
    string += address.administrativeArea + ', ';
    string += address.postalCode;

    return string;
  }

  async getPostcodesFromInput(input: string) {
    const response = await this.http.get('https://api.postcodes.io/postcodes/' + input + '/autocomplete').toPromise();

    return response['result'];
  }
}
