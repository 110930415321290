import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, search: string, replace: string, all: boolean = false): string {
    return (all === true) ? value.split(search).join(replace) : value.replace(search, replace);
  }
}
