import { Salon } from 'src/app/interfaces/salon';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { Category } from 'src/app/interfaces/category';
import { Service } from 'src/app/interfaces/service';
import { ApiService } from 'src/app/services/api/api.service';

export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { closeOthers: true });
}

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  providers: [{provide: AccordionConfig, useFactory: getAccordionConfig}]
})
export class CategoryListComponent implements OnInit, OnChanges {

  activeServices: Service[] = [];
  servicesByCategory: Category[] = [];

  selectedService: any; 

  @Input() categories: Category[];
  @Input() salon: Salon;
  @Input() salons: Salon[];
  @Input() salon_slug: string;

  mobileSelect: number = 0;

  constructor(
    private api: ApiService,
  ) {}

  ngOnInit() {
    if (this.categories) {
      this.prepareData(this.categories);
    } else if (this.salon) {
      this.categories = this.salon.categories;
      this.prepareData(this.categories);
    }
  }

  ngOnChanges() {
    if (this.categories) {
      this.prepareData(this.categories);
    } else if (this.salon) {
      this.categories = this.salon.categories;
      this.prepareData(this.categories);
    }
  }

  prepareData(categories: Category[]) {
    this.servicesByCategory = categories;
    this.activeServices = this.servicesByCategory[0]?.services || [];
  }

  changeServices(category: {services: []}, element: HTMLElement, index: number) {
    this.activeServices = category.services;
    this.mobileSelect = index;

    element.closest('.nav.nav-pills').querySelector('.active').classList.remove('active');
    element.classList.add('active');
  }

  changeServicesMobile() {
    // type-cast the response of querySelector to HTMLElement - otherwise angular/node throws an error on compile 
    const el = <HTMLElement>document.querySelector('a[data-index="' + this.mobileSelect + '"]');

    el.click();
  }

  segmentChanged(event: any) {
    this.activeServices = this.servicesByCategory[event.detail.value].services;
  }

  setActiveSalon(salon: Salon, event: MouseEvent) {
    const currentSalon = JSON.parse(localStorage.getItem('current-salon'));

    if (currentSalon === null) {
      localStorage.setItem('current-salon', JSON.stringify(salon));
    }

    localStorage.setItem('next-salon', JSON.stringify(salon));
  }
}
