import { Component, Input, OnInit } from "@angular/core";
import { SettingsService } from "./../../services/settings/settings.service";

@Component({
  selector: "app-app-store-logos",
  templateUrl: "./app-store-logos.component.html",
  styleUrls: ["./app-store-logos.component.scss"],
})
export class AppStoreLogosComponent implements OnInit{
  @Input() classes: string[] = null;

  ios_app_link:string = '';
  android_app_link:string = '';

  constructor(
    private settingsService: SettingsService,
  ){}

  async ngOnInit(){

    var settings = await this.settingsService.getSettings();

    this.ios_app_link = settings.app.ios_app_link;
    this.android_app_link = settings.app.android_app_link;

  }

}
