import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertController, NavController, LoadingController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loggedIn = false;

  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private auth: AuthService,
    private loadingController: LoadingController,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loading = await this.loadingController.create({
      message: 'Loading...',
      duration: 5000,
    });
    await loading.present();

    if (this.auth.getAccessToken()) { 
      const loggedIn = await this.auth.isLoggedIn();

      if (loggedIn === true) {
        this.loggedIn = true;
        setTimeout(() => loading.dismiss(), 1000);
        return true; 
      }
    }

    this.loggedIn = false;

    const alert = await this.alertController.create({
      header: 'You need to be logged in to view this page',
      message: 'Press OK to go to login, or Cancel to remain on the current page.',
      buttons: [
        'Cancel',
        {
          text: 'OK',
          handler: () => {
            this.navController.navigateRoot('/login', {
              queryParams: {
                return: state.url
              }
            });
          }
        }
      ],
      mode: 'md'
    });

    await loading.dismiss();

    await alert.present();

    return false;
  }
}
