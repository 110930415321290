import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {

  @Input() rating: number;
  @Input() index: number;

  rating_class: any;

  constructor() { }

  ngOnInit() {
    const current_star = this.index + 1;
    this.index;
    this.rating_class = {
      'far': this.rating < this.index,
      'fas': this.rating >= this.index,
      'fa-star-half-alt': this.rating > this.index && this.rating < current_star,
      'fa-star': (this.rating >= current_star) || (this.rating < this.index),
    }
  }
}
