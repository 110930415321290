import { Component, OnInit } from '@angular/core';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';

@Component({
  selector: 'app-favourites-slider',
  templateUrl: './favourites-slider.component.html',
  styleUrls: ['./favourites-slider.component.scss'],
})
export class FavouritesSliderComponent implements OnInit {

  favourites = [];
  
  slickConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5 * 1000, // 5 seconds
    centerMode: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          dots: true, 
          arrows: true
        }
      }
    ]
  };

  constructor(
    private favouritesService: FavouritesService,
  ) { }

  ngOnInit() {
    this.favouritesService.favourites$.subscribe(favourites => {
      this.favourites = [];

      if (favourites['salons'].length > 0) {
        favourites['salons'].forEach(salon => {
          this.favourites.push({
            img_url: salon.banner_url,
            text: salon.name,
            type: 'salon',
            id: salon.slug,
          })
        });
      }
      
      if (favourites['services'].length > 0) {
        favourites['services'].forEach(service => {
          this.favourites.push({
            img_url: service.image_url,
            text: service.name + ' @ ' + service.salon_name,
            type: 'service',
            id: service.id,
          })
        });
      }
    })
  }

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }
}
