import { FcmService } from "./../../services/fcm/fcm.service";
import { Component, DoCheck, ViewChild } from "@angular/core";

@Component({
  selector: "app-main-container",
  templateUrl: "./main-container.component.html",
  styleUrls: ["./main-container.component.scss"],
})
export class MainContainerComponent implements DoCheck {
  @ViewChild("mainContainer") main_container;

  constructor() {}

  ngDoCheck() {
    this.doStuff();
    // this.fcm.setupFCM();
  }

  private doStuff() {
    const page = document.querySelector("ion-router-outlet .ion-page:not(.ion-page-hidden)");

    if (this.main_container) {
      if (page) {
        const footer = page.querySelector("app-footer");
        const navbar = document.querySelector("header");

        if (footer) {
          const content_wrapper = this.main_container.nativeElement.querySelector("#content-wrapper");
          const content_height = navbar.clientHeight + content_wrapper.clientHeight + footer.clientHeight;

          if (content_height < document.body.clientHeight) {
            footer.classList.add("position-absolute");
          } else {
            footer.classList.remove("position-absolute");
          }
        }
      }
    }
  }
}
