import { ApiService } from './../api/api.service';
import { Voucher } from './../../interfaces/voucher';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  private active_voucher: Voucher;
  private voucher_error: boolean = false;
  private voucher_error_message: string = '';

  constructor(private api: ApiService) {
    const voucher: Voucher = JSON.parse(localStorage.getItem('in_use_voucher'));

    if (voucher) {
      this.active_voucher = voucher;
      this.checkIfCurrentVoucherActive();
    }
  }

  checkIfCurrentVoucherActive() {
    const data = {
      voucher: this.active_voucher.code,
      salon_id: this.active_voucher.salon_id,
    };

    this.checkVoucher(data);
  }

  checkIfVoucherKeepsMinOrderValue() {
    return this.active_voucher.min_order_value;
  }

  private checkVoucher(data: {voucher: string, salon_id: number}, resolve = null, reject = null) {
    this.api.post('/vouchers/check', data).subscribe(response => {
      if (response['success'] === true) {
        const voucher: Voucher = response['data'];

        this.setVoucher(voucher);

        if (resolve !== null) {
          resolve(true);
        }
      } else {
        this.removeVoucher('This voucher is not valid. Please try another voucher.', true);
        
        if (resolve !== null) {
          reject(false);
        }
      }
    });
  }

  checkVoucherCode(code: string, salon_id: number = null) {
    return new Promise<any>(
      (resolve, reject) => {
        const data = {
          voucher: code,
          salon_id: salon_id,
        };
    
        this.checkVoucher(data, resolve, reject);
      }
    );
  }

  getVoucherErrorStatus() {
    return {
      error: this.voucher_error,
      message: this.voucher_error_message
    };
  }

  setVoucher(voucher: Voucher) {
    this.active_voucher = voucher;

    localStorage.setItem('in_use_voucher', JSON.stringify(voucher));
  }

  removeVoucher(message: string = '', error: boolean = false) {
    this.active_voucher = null;

    localStorage.removeItem('in_use_voucher');

    this.voucher_error = error;
    this.voucher_error_message = message;
  }

  getVoucherDiscount(order_total: number) {
    let deduction: number = null;
    let text: string = null;
    let active = false;
    let voucher_takes_order_below_1_pound = false;

    if (this.active_voucher) {
      if (this.active_voucher.voucher_type === 'fixed') {
        text = '£' + this.active_voucher.voucher_amount.toFixed(2).replace('.00', '');

        deduction = this.active_voucher.voucher_amount;
      } else {
        text = this.active_voucher.voucher_amount.toFixed(2).replace('.00', '') + '% Off';

        const percentage = this.active_voucher.voucher_amount / 100;
        deduction = order_total * percentage;
      }
      
      active = true;

      if(deduction > (order_total - 1)){

        voucher_takes_order_below_1_pound = true;
        deduction = null;
        text = null;
        active = false;

      }

    }

    return {
      deduction, 
      text, 
      active, 
      voucher_takes_order_below_1_pound, 
      code: active ? this.active_voucher.code : '',
    };
  }
}
